<template>
  <div class="deviceInfo">
    <a-modal
      v-model="visible"
      title="新增/编辑设备"
      :width="851"
      centered
      @ok="confirmDevice"
    >
      <div class="modal_info">
        <div class="modal_info_title">基本信息</div>
        <div class="modal_info_content">
          <a-form-model
            :model="pageData"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
            <a-row>
              <a-col :span="24">
                <a-form-model-item
                  label="设备分类"
                  prop="sort"
                  :label-col="{ span: 3, offset: 1 }"
                  :wrapper-col="{ span: 14 }"
                >
                  <a-radio-group v-model="pageData.sort">
                    <a-radio :value="1">自动设备</a-radio>
                    <a-radio :value="2">非自动设备</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="设备类型" prop="type">
                  <a-select v-model="pageData.type">
                    <a-select-option :value="1">视频监控</a-select-option>
                    <a-select-option :value="2">访客机</a-select-option>
                    <a-select-option :value="3">硬盘录像机</a-select-option>
                    <a-select-option :value="4">门禁设备</a-select-option>
                    <a-select-option :value="5">摄像头</a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="设备名称" prop="name">
                  <a-input v-model="pageData.name" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="设备位置" prop="address">
                  <a-input v-model="pageData.address" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="设备编码" prop="code">
                  <a-input v-model="pageData.code" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="保修日期" prop="repairTimeStr">
                  <a-date-picker
                    v-model="pageData.repairTimeStr"
                    format="YYYY-MM-DD"
                    class="searchIpt"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="供应商">
                  <a-input v-model="pageData.supplier" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="12">
                <a-form-model-item label="联系人">
                  <a-input v-model="pageData.contacts" />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="联系方式">
                  <a-input v-model="pageData.contactInfo" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import RepairInfo from "./repairInfo";
import { addDevice, updateDevice, deviceInfo } from "@/api/deviceManage";
export default {
  name: "deviceInfo",
  data() {
    return {
      visible: false,
      pageData: {
        sort: 1,
        type: 1,
        name: "",
        address: "",
        code: "",
        repairTimeStr: "",
        supplier: "",
        contacts: "",
        contactInfo: "",
      },
      labelCol: { span: 6, offset: 2 },
      wrapperCol: { span: 14 },
    };
  },
  components: {
    RepairInfo,
  },
  props: {
    deviceId: {
      type: Number,
      default: -1,
    },
  },
  methods: {
    moment,
    showModal() {
      if (this.deviceId > 0) {
        this.getInfo();
      } else {
        this.pageData = {
          sort: 1,
          type: 1,
          name: "",
          address: "",
          code: "",
          repairTimeStr: "",
          supplier: "",
          contacts: "",
          contactInfo: "",
        };
      }
      this.visible = true;
    },
    openNotification: function(type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
    confirmDevice() {
      let data = {
        ...this.pageData,
        repairTimeStr: moment(this.pageData.repairTimeStr).format("YYYY-MM-DD"),
      };
      if (this.deviceId > 0) {
        data.id = this.deviceId;
        updateDevice(data)
          .then((res) => {
            if (res.data.code == 200) {
              this.openNotification("success", res.data.msg);
              this.visible = false;
              this.$emit("refreshTable", {});
            } else {
              this.openNotification("error", res.data.msg);
            }
          })
          .catch((res) => {
            this.openNotification("error", res.data.msg);
          });
      } else {
        addDevice(data)
          .then((res) => {
            if (res.data.code == 200) {
              this.openNotification("success", res.data.msg);
              this.visible = false;
              this.$emit("refreshTable", {});
            } else {
              this.openNotification("error", res.data.msg);
            }
          })
          .catch((res) => {
            this.openNotification("error", res.data.msg);
          });
      }
    },
    getInfo() {
      let data = {
        id: this.deviceId,
      };
      deviceInfo(data).then((res) => [
        (this.pageData = {
          sort: res.data.data.sort,
          type: res.data.data.type,
          name: res.data.data.name,
          address: res.data.data.address,
          code: res.data.data.code,
          repairTimeStr: res.data.data.repairTimeStr,
          supplier: res.data.data.supplier,
          contacts: res.data.data.contacts,
          contactInfo: res.data.data.contactInfo,
        }),
      ]);
    },
  },
};
</script>

<style lang="less" scoped>
.modal_info_content {
  margin-top: 20px;
}
.modal_info {
  margin: 0 50px;
}
.repairRecord {
  margin: 0 50px;
}
.repairRecord_title {
  margin: 20px 0;
}
.green {
  color: #62b968;
}
.orange {
  color: #f08b49;
}
.searchIpt {
  width: 100%;
}
</style>
