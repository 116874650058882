<template>
  <div class="uploadFile">
    <a-modal
      v-model="visible"
      okText="上传"
      :width="386"
      centered
      :confirmLoading="confirmLoading"
      @ok="confirmDevice"
      :closable="false"
    >
      <div class="modal_head">
        <a-icon type="check-circle" theme="filled" class="checkCircle" />
        <span>请根据模板规范填写后上传文件</span>
      </div>
      <a-form-model
        :model="pageData"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="导入文件">
          <a-upload
            :file-list="fileList"
            :remove="handleRemove"
            :beforeUpload="beforeUpload"
          >
            <a-button> <a-icon type="upload" />选择文件</a-button>
          </a-upload>
          <div class="modal_btns">
            <a-button type="link" @click="downloadModal"
              ><span class="black">下载</span>导入模板</a-button
            >
          </div>
        </a-form-model-item>
        <a-form-model-item label="导入位置">
          <a-select v-model="pageData.inportPosition">
            <a-select-option :value="1">自动设备</a-select-option>
            <a-select-option :value="2">非自动设备</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import { API_HOST } from "@/server/network1";
import { importDevice } from "@/api/deviceManage";
export default {
  name: "uploadFile",
  data() {
    return {
      confirmLoading: false,
      visible: false,
      pageData: {
        inportPosition: 1,
      },
      labelCol: { span: 6, offset: 2 },
      wrapperCol: { span: 14 },
      API_HOST,
      uploadUrl: API_HOST + "/school/device/uploadPicture",
      fileList: [],
    };
  },
  components: {},
  methods: {
    moment,
    showModal() {
      this.pageData.inportPosition = 1;
      this.fileList = [];
      this.visible = true;
      this.confirmLoading = false;
    },
    openNotification: function(type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
    confirmDevice() {
      this.confirmLoading = true;

      let file = this.fileList[0];
      let formData = new FormData();

      formData.append("file", file, file.name);
      formData.append("sort", this.pageData.inportPosition);

      importDevice(formData)
        .then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            this.openNotification("success", res.data.msg);
            this.visible = false;
            this.$emit("refreshTable", {});
          } else {
            this.openNotification("error", res.data.msg);
          }
        })
        .catch((res) => {
          this.confirmLoading = false;
          this.openNotification("error", res.data.msg);
        });
    },
    handleRemove(file) {
      this.fileList = [];
    },

    beforeUpload(file) {
      console.log(file);
      this.fileList = [];
      this.fileList.push(file);
      return false;
    },
    downloadModal() {
      window.open(this.API_HOST + "/school/device/getImportDeviceDemo");
    },
  },
};
</script>

<style lang="less" scoped>
.modal_head {
  margin-left: 5px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  .checkCircle {
    margin-right: 16px;
    color: #52c41a;
    font-size: 22px;
  }
  span {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
  }
}
.modal_btns {
  display: flex;
  align-items: center;
  .black {
    color: #1a1a1b;
  }
}
</style>
