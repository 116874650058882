<template>
  <div class="deviceBill">
    <div class="pageInfo">
      <div class="pageInfo_item">
        <div class="pageInfo_item_title">设备总数</div>
        <div class="pageInfo_item_value">{{ pageInfo.totalCount }}</div>
      </div>
      <div class="pageInfo_item">
        <div class="pageInfo_item_title">设备异常率</div>
        <div class="pageInfo_item_value">{{ pageInfo.repairRate }}</div>
      </div>
      <div class="pageInfo_item">
        <div class="pageInfo_item_title">在线设备</div>
        <div class="pageInfo_item_value green">{{ pageInfo.onlineCount }}</div>
      </div>
      <div class="pageInfo_item">
        <div class="pageInfo_item_title">离线设备</div>
        <div class="pageInfo_item_value red">{{ pageInfo.offLineCount }}</div>
      </div>
      <div class="pageInfo_item">
        <div class="pageInfo_item_title">报修设备</div>
        <div class="pageInfo_item_value yellow">{{ pageInfo.repairCount }}</div>
      </div>
    </div>
    <div class="pageSearch">
      <a-form-model :model="searchForm" layout="inline">
        <a-form-model-item label="设备类型：">
          <a-select
            v-model="searchForm.deviceType"
            class="searchSelect"
            allowClear
            placeholder="请选择"
          >
            <a-select-option :value="1">视频监控</a-select-option>
            <a-select-option :value="2">访客机</a-select-option>
            <a-select-option :value="3">硬盘录像机</a-select-option>
            <a-select-option :value="4">门禁设备</a-select-option>
            <a-select-option :value="5">摄像头</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="设备状态：">
          <a-select
            v-model="searchForm.deviceStatus"
            class="searchSelect"
            allowClear
            placeholder="请选择"
          >
            <a-select-option :value="0">在线</a-select-option>
            <a-select-option :value="1">离线</a-select-option>
            <a-select-option :value="2">报修</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="设备名称：">
          <a-input
            v-model="searchForm.deviceName"
            placeholder="请输入"
            class="searchSelect"
          />
        </a-form-model-item>
        <a-form-model-item label="设备编码：">
          <a-input
            v-model="searchForm.deviceCode"
            placeholder="请输入"
            class="searchSelect"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" class="searchBtn" @click="search"
            >查询</a-button
          >
          <a-button class="searchBtn" @click="reset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="pageContent">
      <div class="pageContent_head">
        <div class="pageContent_title">设备列表</div>
        <div class="pageContent_head_btns">
          <a-button type="primary" class="headBtn" @click="openAddDevice(-1)"
            >新增</a-button
          >
          <a-button @click="InportFile">批量导入</a-button>
        </div>
      </div>
      <div class="deviceType">
        <a-radio-group
          v-model="deviceTypeSelected"
          button-style="solid"
          @change="changeTypeSelect"
        >
          <a-radio-button :value="1"> 自动设备 </a-radio-button>
          <a-radio-button :value="2"> 非自动设备 </a-radio-button>
        </a-radio-group>
      </div>
      <div class="pageContent_table">
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :loading="tableLoading"
          size="middle"
          :pagination="pagination"
        >
          <div slot="deviceType" slot-scope="data">
            <!-- {{ data.type == 1 ? "视频监控" : "访客机" }} -->
            {{
              data.type == 1
                ? "视频监控"
                : data.type == 2
                ? "访客机"
                : data.type == 3
                ? "硬盘录像机"
                : data.type == 3
                ? "门禁设备"
                : "摄像头"
            }}
          </div>
          <div slot="deviceStatus" slot-scope="data">
            <span
              :class="
                data.status == 1 ? 'green' : data.status == 2 ? 'red' : 'yellow'
              "
            >
              {{
                data.status == 1 ? "在线" : data.status == 2 ? "离线" : "报修"
              }}
            </span>
          </div>
          <div slot="deviceTime" slot-scope="data">
            <span
              :class="
                data.repairTimeDay && data.repairTimeDay <= 3 ? 'lessRed' : ''
              "
              >{{ data.repairTimeDay ? data.repairTimeDay + "天" : "" }}</span
            >
          </div>
          <div slot="QRCode" slot-scope="data">
            <img
              :src="data.qrCodeUrl"
              class="qrCode"
              @click="downLoadQR(data)"
            />
          </div>
          <div slot="actions" slot-scope="data">
            <a-button
              type="link"
              v-if="data.status !== 3"
              @click="openAddRepair(data.code)"
              >报修</a-button
            >
            <a-button type="link" @click="openModal(data.id)">查看</a-button>
            <a-button type="link" @click="openAddDevice(data.id)"
              >编辑</a-button
            >
            <a-popconfirm
              title="确定要删除吗"
              ok-text="确定"
              cancel-text="取消"
              @confirm="confirm(data.id)"
              @cancel="cancel"
            >
              <a-button type="link">删除</a-button>
            </a-popconfirm>
          </div>
        </a-table>
      </div>
    </div>
    <DeviceInfo ref="deviceInfo" :deviceId="deviceId" />
    <AddDevice
      ref="addDevice"
      :deviceId="deviceId"
      @refreshTable="refreshTable"
    />
    <UploadFile ref="uploadFile" @refreshTable="refreshTable" />
    <AddRepair
      ref="addRepair"
      :deviceCode="deviceCode"
      @refreshTable="refreshTable"
    />

    <a-modal
      title="二维码"
      v-model="qrModal"
      centered
      destroyOnClose
      @ok="downloadQRCode"
      ok-text="下载"
      :width="370"
    >
      <div class="printArea" ref="printArea">
        <div>
          设备类型：
          {{
            printData.deviceType == 1
              ? "视频监控"
              : printData.deviceType == 2
              ? "访客机"
              : printData.deviceType == 3
              ? "硬盘录像机"
              : printData.deviceType == 4
              ? "门禁设备"
              : "摄像头"
          }}

          <!-- {{ printData.deviceType == 1 ? "视频监控" : "访客机" }} -->
        </div>
        <div>设备名称：{{ printData.deviceName }}</div>
        <div>设备位置：{{ printData.deviceAddress }}</div>
        <img :src="printData.qrCode" />
      </div>
    </a-modal>
  </div>
</template>

<script>
import DeviceInfo from "./components/deviceInfo";
import AddDevice from "./components/addDevice";
import UploadFile from "./components/uploadFile";
import AddRepair from "./components/addRepair";
import { getDeviceDetail, getDeviceList, delDevice } from "@/api/deviceManage";
import html2canvas from "html2canvas";

export default {
  name: "deviceList",
  data() {
    return {
      tableLoading: false,
      pagination: {
        current: 1,
        showTotal: (total, range) => `第${range.join("-")}条/总共${total}条`,
        onChange: (page, pageSize) => this.changePage(page, pageSize),
        onShowSizeChange: (current, size) => this.showSizeChange(current, size),
        total: 0,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
      },
      pageInfo: {
        totalCount: 0,
        repairRate: "0%",
        onlineCount: 0,
        offLineCount: 0,
        repairCount: 0,
      },
      qrModal: false,
      searchForm: {
        deviceType: undefined,
        deviceStatus: undefined,
        deviceName: "",
        deviceCode: "",
      },
      columns: [
        {
          title: "设备名称",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "设备类型",
          align: "center",
          scopedSlots: {
            customRender: "deviceType",
          },
        },
        {
          title: "设备编码",
          dataIndex: "code",
          align: "center",
        },
        {
          title: "设备状态",
          align: "center",
          scopedSlots: {
            customRender: "deviceStatus",
          },
        },
        {
          title: "维保倒计时",
          align: "center",
          scopedSlots: {
            customRender: "deviceTime",
          },
        },
        {
          title: "二维码",
          align: "center",
          scopedSlots: {
            customRender: "QRCode",
          },
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: {
            customRender: "actions",
          },
        },
      ],
      dataSource: [],
      deviceTypeSelected: 1,
      deviceId: -1,
      deviceCode: "",
      printData: {
        deviceType: 1,
        deviceNmae: "1号摄像头",
        deviceAddress: "1号教学楼一楼东1位",
        qrCode: "",
      },
    };
  },
  components: {
    DeviceInfo,
    AddDevice,
    UploadFile,
    AddRepair,
  },
  created() {
    this.getHead();
    this.getList();
  },
  methods: {
    openNotification: function(type, msg) {
      this.$notification[type]({
        message: "系统提示",
        description: msg,
      });
    },
    openModal(id) {
      this.deviceId = id;
      this.$nextTick(() => {
        this.$refs.deviceInfo.showModal();
      });
    },
    reset() {
      this.searchForm = {
        deviceType: "",
        deviceStatus: "",
        deviceName: "",
        deviceCode: "",
      };
    },
    search() {
      this.pagination.current = 1;
      this.getList();
    },
    openAddDevice(id) {
      this.deviceId = id;
      this.$nextTick(() => {
        this.$refs.addDevice.showModal();
      });
    },
    confirm(id) {
      let data = {
        id: id,
      };
      delDevice(data).then((res) => {
        if (res.data.code == 200) {
          this.openNotification("success", res.data.msg);
          this.getList();
        } else {
          this.openNotification("error", res.data.msg);
        }
      });
    },
    cancel() {},
    changeTypeSelect() {
      this.pagination.current = 1;
      this.getList();
    },
    refreshTable() {
      this.getList();
    },
    InportFile() {
      this.$refs.uploadFile.showModal();
    },
    openAddRepair(code) {
      this.deviceCode = code;
      this.$nextTick(() => {
        this.$refs.addRepair.showModal();
      });
    },
    getHead() {
      let data = {};
      getDeviceDetail(data).then((res) => {
        this.pageInfo = res.data.data;
      });
    },
    getList() {
      let data = {
        status: this.searchForm.deviceStatus,
        sort: this.deviceTypeSelected,
        type: this.searchForm.deviceType,
        name: this.searchForm.deviceName,
        code: this.searchForm.deviceCode,
        page: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };
      getDeviceList(data).then((res) => {
        this.dataSource = res.data.data.records;
        this.pagination.total = res.data.data.total;
      });
    },
    changePage(page) {
      this.pagination.current = page;
      this.getList();
    },
    showSizeChange(current, size) {
      this.pagination.current = current;
      this.pagination.pageSize = size;
      this.getList();
    },
    downLoadQR(info) {
      // let link = document.createElement("a");
      // let arr = url.split("/");
      // let len = arr.length;
      // let name = arr[len - 1];
      // fetch(url)
      //   .then((res) => res.blob())
      //   .then((blob) => {
      //     link.href = URL.createObjectURL(blob);
      //     link.download = name;
      //     document.body.appendChild(link);
      //     link.click();
      //   });
      this.printData = {
        deviceType: info.type,
        deviceName: info.name,
        deviceAddress: info.address,
        qrCode: info.qrCodeUrl,
      };
      this.qrModal = true;
    },
    downloadQRCode() {
      this.$nextTick(() => {
        html2canvas(this.$refs.printArea).then((canvas) => {
          let dataURL = canvas.toDataURL("image/png");
          if (dataURL !== "") {
            let alink = document.createElement("a");
            alink.href = dataURL;
            alink.download = this.printData.deviceName;
            alink.click();
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.deviceBill {
  width: 100%;
  height: 100%;
}
.pageHeader {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  color: rgba(16, 16, 16, 100);
  background: white;
  text-indent: 27px;
  span {
    font-weight: bold;
    margin-right: 10px;
  }
}
.pageInfo {
  margin-bottom: 10px;
  width: 100%;
  height: 101px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
}
.pageInfo_item {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: rgba(16, 16, 16, 100);
}
.pageSearch {
  margin-bottom: 20px;
  background: white;
  padding: 20px;
}
.searchBtn {
  margin-right: 10px;
}
.searchSelect {
  width: 200px;
}
.pageContent_title {
  height: 20px;
  display: flex;
  align-items: center;
  margin-left: 27px;
  position: relative;
  color: rgba(16, 16, 16, 100);
  font-weight: bold;
}
.pageContent_title::before {
  width: 4px;
  height: 20px;
  left: -7px;
  top: 0;
  position: absolute;
  content: "";
  background-color: #555555;
}
.lessRed {
  color: #c71414;
}
.pageContent_table {
  background-color: white;
}
.deviceType {
  margin: 20px 0 20px 10px;
}
.green {
  color: #62b968;
}
.red {
  color: #eb0606;
}
.yellow {
  color: #f08b49;
}
.qrCode {
  width: 24px;
  height: 24px;
}
.pageContent_head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headBtn {
  margin-right: 10px;
}
.printArea {
  width: max-content;
  padding: 10px;
}
</style>
